<template>
  <div id="analysis-report-shell">
    <analysis-template @export="onExport" :currentdate="date" :isExport="true"></analysis-template>
  </div>
</template>

<script>
import AnalysisTemplate from "../../components/AnalysisTemplate.vue";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      date:null
    };
  },

  components: {
    AnalysisTemplate,
  },

  created() {
    console.log(this.$route.query);
    let query=this.$route.query;
    if(query.hasOwnProperty('date')){
      this.date=query.date;
    }
    var me = this;

    if (localStorage.getItem("loggedIn")) {
      var loggedIn = JSON.parse(localStorage.getItem("loggedIn"));
      this.$store.commit("setLoggedIn", loggedIn);
    } else {
      this.$router.push("/login");
    }
  },

  methods: {
    onExport() {
      console.log("aaaa");
      setTimeout(function () {
        window.print();
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
#analysis-report-shell {
  width: 1050px;
  margin: 0 auto;
  padding: 20px 0 0;
  color: #181818;
  position: relative;
}
</style>
